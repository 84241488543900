import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SignaturePad from "react-signature-canvas";
import * as yup from "yup";

import {
  Box,
  FormControl,
  FormLabel,
  Textarea,
  RadioGroup,
  Radio,
  UnorderedList,
  ListItem,
  Input,
  Button,
  HStack,
  VStack,
  Flex,
  Text,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { BsXCircle } from "react-icons/bs";
import { VscWarning } from "react-icons/vsc";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  address: yup.string().required("Address is required"),
  records: yup.string().required("Public Records is required"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Invalid phone number"
    ),
  email: yup
    .string()
    .required()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  examineRecords: yup.string().required("Please choose examine or copies").nullable(),
  recordType: yup.string().when("examineRecords", {
    is: (examinerecords) => examinerecords === "copies",
    then: yup.string().required('Please choose a record type').nullable()
}).nullable(),
  // recordType: yup.string().required("Please choose a record type").nullable(),
  applicantSignature: yup
    .string()
    .required("Signature is required.")
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature must be png"
    ),
  //dateOfRequest: yup.date().required("Date is required").typeError("Must be a date"),
});

const errorStyles = {
  color: "#bf1650",
  fontSize: "1rem",
};

function RequiredSpan() {
  return <span style={{ color: "#bf1650" }}>*</span>;
}
const scrollTop = () => {
  window.scrollTo(0, 0);
};


const RequestToExamineRecords = () => {

  const defaultValues = {
    licenseType: "",
    applicantSignature: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const watchData = watch();

  // create a Ref to access our form element
  const formRef = useRef(null);

  const FormSuccess = () => (
    <Box m="20">
       <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Message submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
    Thanks for your submission. We'll be in touch soon.
  </AlertDescription>
    </Alert>
    </Box>
  );

  const sendFormData = async (event) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
      console.log("something went wrong with the ref");
    }

    const formData = new FormData(formRef.current);

    // convert signature to blob
    const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");

    // append blob to formData
    formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");


    // Use console.log() to see the data that is being sent to the server
    //console.log(Array.from(formData.entries()));

      fetch('/.netlify/functions/send-public-records-request', {
           method: 'POST',
           body: formData,
       })
       // Convetring the response to JSON breaks status code
       .then(res => {
        console.log(res)
           if (res.status === 200) {
                 setSucceeded(true)
                 setProcessing(false)
                 reset()
                 scrollTop()
             } else {
                 setSucceeded(false)
                 console.log("network error")
                 alert("Network error. Please try again later.")
             }
    }
 )
  };

  {
    /* Signature Pad */
  }
  let sigCanvas = useRef({});

  const formatIntoPng = () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current.toDataURL();
      return dataURL;
    }
  };

  const clear = () => sigCanvas.current.clear();

  return (
    <>
      <Box id="theForm">
        {succeeded ? (
          <FormSuccess />
        ) : (
          <>

            <Box mb="8">
              <Text as="b" display="block">
                Please complete the following:
              </Text>
            </Box>

            <form onSubmit={handleSubmit(sendFormData)} ref={formRef}>
              {/* <Box mb="8" w={{ base: "100%", md: "50%" }}>
                <FormLabel>Date of Request:<RequiredSpan /></FormLabel>
                <Input
                  type="date"
                  {...register("dateOfRequest")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.dateOfRequest && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.dateOfRequest?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box> */}

              <Box mb="8">
                <FormLabel>Name:<RequiredSpan /></FormLabel>
                <Input type="text" {...register("name")} autoComplete="on" />
                <p style={errorStyles}>
                  {errors.name && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.name?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Address:<RequiredSpan /></FormLabel>
                <Input type="text" {...register("address")} autoComplete="on" placeholder="123 Main St., Driggs, ID 83455" />
                <p style={errorStyles}>
                  {errors.address && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.address?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Flex flexWrap="wrap">
                <Box mb="8" w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Phone Number:<RequiredSpan /></FormLabel>
                  <Input
                    type="tel"
                    {...register("phoneNumber")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.phoneNumber && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.phoneNumber?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box
                  mb="8"
                  w={{ base: "100%", md: "50%" }}
                  pl={{ base: 0, md: "4" }}
                >
                  <FormLabel>E-Mail:<RequiredSpan /></FormLabel>
                  <Input
                    type="email"
                    {...register("email")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.email && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.email?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box mb="4">
                <FormLabel>
                  I hereby request, pursuant to Idaho Code 74-102, to examine
                  and / or copy the following public record(s):<RequiredSpan />
                </FormLabel>
                <Textarea
                  {...register("records")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.records && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.records?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="4">
                <FormControl>
                  <RadioGroup defaultValue="">
                    <HStack spacing="18px" align="left">
                      <Radio
                        value="examine records"
                        name="examineRecords"
                        {...register("examineRecords", {required: true})}
                      >
                        I wish to merely examine these records.
                      </Radio>
                      <Radio
                        value="copies"
                        name="examineRecords"
                        {...register("examineRecords")}
                      >
                        I wish copies of these records in digtal or printed format
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
                <p style={errorStyles}>
                  {errors.examineRecords && (
                        <HStack spacing="5px">
                          <VscWarning />{" "}
                          <Box>{errors.examineRecords?.message}</Box>
                        </HStack>
                      )}
                </p>
              </Box>

              <Box mb="8">
                {watchData.examineRecords === "copies" && (
                  <>
                  <  FormControl>
                      <RadioGroup>
                        <VStack spacing="18px" align="left">
                          <Radio
                            value="electronic"
                            name="recordType"
                            {...register("recordType")}
                          >
                            in electronic form (to be emailed to the email address provided)
                          </Radio>
                          <Text>or</Text>
                          <Radio
                            value="printed"
                            name="recordType"
                            {...register("recordType")}
                          >
                            hard copies (to be picked up at City Hall)
                          </Radio>
                        </VStack>
                      </RadioGroup>
                    </FormControl>
                    <p style={errorStyles}>
                    {errors.recordType && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.recordType?.message}</Box>
                      </HStack>
                    )}
                  </p>
                  </>
                )}
              </Box>

              <Box mb="8">
                <Text>
                  Please note: Fees may be incurred pursuant to Idaho Code
                  §74-102 if the following conditions apply:
                </Text>
                <UnorderedList pl={{ base: 0, md: "4" }}>
                  <ListItem>
                    If the request is more than one hundred (100) pages of paper
                    records; or
                  </ListItem>
                  <ListItem>
                    If the actual labor associated with locating and copying
                    documents for a request exceeds two (2) person hours.
                  </ListItem>
                </UnorderedList>
              </Box>

              <Box mb="8">
                <FormControl isInvalid={!!errors?.applicantSignature?.message}>
                  <FormLabel>Signature:</FormLabel>
                  <Box>
                    <Controller
                      name="applicantSignature"
                      control={control}
                      render={({ field }) => (
                        <SignaturePad
                          ref={sigCanvas}
                          onEnd={() =>
                            field.onChange(
                              formatIntoPng(sigCanvas.current.toDataURL())
                            )
                          }
                          penColor="black"
                          canvasProps={{
                            style: {
                              border: "1px solid #e2e8f0",
                              borderRadius: "0.375rem",
                              width: "100%",
                              height: "250",
                              backgroundColor: "white",
                            },
                          }}
                        />
                      )}
                    />
                    <Text mt="4">
                      I acknowledge by my signature that the records sought by
                      this request will not be used for a mailing list or
                      telephone lists and will be follow the regulations as set
                      forth in Idaho Code §74-120.
                    </Text>

                    {errors.applicantSignature && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.applicantSignature?.message}</Box>
                      </HStack>
                    )}
                  </Box>
                  <Box>
                    <input
                      type="hidden"
                      value={new Date()}
                      name="timestamp"
                      {...register("timestamp", { required: true })}
                    />
                  </Box>
                </FormControl>
                <Box my="2" textAlign="right">
                  <Button size="sm" onClick={clear} colorScheme="red">
                    <Icon as={BsXCircle} mr="2" />
                    Clear Signature
                  </Button>
                </Box>
              </Box>


              <Box my="8">
              <Button 
                //disabled={!isValid || processing}
                type="submit"
                mt={4}
                isLoading={processing}
                loadingText='Submitting'
              >
                Submit
              </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
    </>
  );
};

export default RequestToExamineRecords;
